// Generated by Framer (7aa0232)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["V8WfyhniL", "elO2a4gQj"];

const variantClassNames = {elO2a4gQj: "framer-v-ygfgsd", V8WfyhniL: "framer-v-c8v2tz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 100, delay: 0, mass: 10, stiffness: 327, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Default: "V8WfyhniL", Hover: "elO2a4gQj"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, pcqW8Nj95: image ?? props.pcqW8Nj95 ?? {src: new URL("assets/1024/UIegrAhcY3qBGfvSQnUNxDtYco.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/UIegrAhcY3qBGfvSQnUNxDtYco.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/UIegrAhcY3qBGfvSQnUNxDtYco.jpg", import.meta.url).href} 1024w, ${new URL("assets/2048/UIegrAhcY3qBGfvSQnUNxDtYco.jpg", import.meta.url).href} 2048w, ${new URL("assets/4096/UIegrAhcY3qBGfvSQnUNxDtYco.jpg", import.meta.url).href} 4096w, ${new URL("assets/UIegrAhcY3qBGfvSQnUNxDtYco.jpg", import.meta.url).href} 6000w`}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "V8WfyhniL"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, pcqW8Nj95, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "V8WfyhniL", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter10pkx20 = activeVariantCallback(async (...args) => {
setVariant("elO2a4gQj")
})

const onMouseLeaveir8u67 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("V8WfyhniL"), 200)
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-vNSyR", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-c8v2tz", className)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"V8WfyhniL"} onMouseEnter={onMouseEnter10pkx20} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", opacity: 0.83, ...style}} variants={{elO2a4gQj: {opacity: 1}}} {...addPropertyOverrides({elO2a4gQj: {"data-framer-name": "Hover", onMouseLeave: onMouseLeaveir8u67}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 4003, intrinsicWidth: 6000, pixelHeight: 4003, pixelWidth: 6000, sizes: "min(790px, 100vw)", ...toResponsiveImage(pcqW8Nj95)}} className={"framer-omed6"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"wGhfT7Rmz"} style={{filter: "brightness(1) saturate(0)", WebkitFilter: "brightness(1) saturate(0)"}} variants={{elO2a4gQj: {filter: "brightness(1.04) saturate(1.12)", WebkitFilter: "brightness(1.04) saturate(1.12)"}}} {...addPropertyOverrides({elO2a4gQj: {background: {alt: "", fit: "fill", intrinsicHeight: 4003, intrinsicWidth: 6000, pixelHeight: 4003, pixelWidth: 6000, sizes: "calc(min(790px, 100vw) * 1.1)", ...toResponsiveImage(pcqW8Nj95)}}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-vNSyR [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vNSyR .framer-10eevit { display: block; }", ".framer-vNSyR .framer-c8v2tz { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 555px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 790px; }", ".framer-vNSyR .framer-omed6 { aspect-ratio: 1.4234234234234233 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 555px); overflow: hidden; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-vNSyR .framer-c8v2tz { gap: 0px; } .framer-vNSyR .framer-c8v2tz > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-vNSyR .framer-c8v2tz > :first-child { margin-left: 0px; } .framer-vNSyR .framer-c8v2tz > :last-child { margin-right: 0px; } }", ".framer-vNSyR.framer-v-ygfgsd .framer-omed6 { height: var(--framer-aspect-ratio-supported, 611px); width: 110%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 555
 * @framerIntrinsicWidth 790
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"elO2a4gQj":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"pcqW8Nj95":"image"}
 */
const FramerRDOM6xC9d: React.ComponentType<Props> = withCSS(Component, css, "framer-vNSyR") as typeof Component;
export default FramerRDOM6xC9d;

FramerRDOM6xC9d.displayName = "Ticker Images Container";

FramerRDOM6xC9d.defaultProps = {height: 555, width: 790};

addPropertyControls(FramerRDOM6xC9d, {variant: {options: ["V8WfyhniL", "elO2a4gQj"], optionTitles: ["Default", "Hover"], title: "Variant", type: ControlType.Enum}, pcqW8Nj95: {__defaultAssetReference: "data:framer/asset-reference,UIegrAhcY3qBGfvSQnUNxDtYco.jpg?originalFilename=photo-1633681926035-ec1ac984418a%3Fcrop%3Dentropy%26cs%3Dsrgb%26fm%3Djpg%26ixid%3DM3wxMzc5NjJ8MHwxfHNlYXJjaHw3fHxzYWxvbnxlbnwwfHx8fDE2OTA1MDA4MDR8MA%26ixlib%3Drb-4.0.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerRDOM6xC9d, [])